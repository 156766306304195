export const NAVIGATION_DATA_ATTRIBUTE_IDS = {
  Advertising: 'Navigation.Advertising',
  AdsAnalytics: 'Navigation.AdvertisingAnalytics',
  Academy: 'Navigation.Academy',
  AddNewAmazonSellerAccount: 'Navigation.AddNewAmazonSellerAccount',
  AdminPanel: 'Navigation.AdminPanel',
  AmazonSellerAccounts: 'Navigation.AmazonSellerAccounts',
  Avatar: 'Navigation.Avatar',
  CategoryTrends: 'Navigation.CategoryTrends',
  FBAReimbursements: 'Navigation.FBAReimbursements',
  HomeDashboard: 'Navigation.HomeDashboard',
  InventoryManager: 'Navigation.InventoryManager',
  Keywords: 'Navigation.Keywords',
  KeywordScout: 'Navigation.KeywordScout',
  KeywordLists: 'Navigation.KeywordLists',
  ListingBuilder: 'Navigation.ListingBuilder',
  ListingGrader: 'Navigation.ListingGrader',
  LogOut: 'Navigation.LogOut',
  ManageProducts: 'Navigation.ManageProducts',
  Marketing: 'Navigation.Marketing',
  MarketInsights: 'Navigation.MarketInsights',
  NicheHunter: 'Navigation.NicheHunter',
  OpportunityFinder: 'Navigation.OpportunityFinder',
  OtherTransactions: 'Navigation.OtherTransactions',
  PAndLStatement: 'Navigation.PAndLStatement',
  ProductResearch: 'Navigation.ProductResearch',
  ProductDatabase: 'Navigation.ProductDatabase',
  ProfitOverview: 'Navigation.ProfitOverview',
  ProductTracker: 'Navigation.ProductTracker',
  ReviewAutomation: 'Navigation.ReviewAutomation',
  RankTracker: 'Navigation.RankTracker',
  ReSyncMwsKeys: 'Navigation.ReSyncMwsKeys',
  ReviewAnalysis: 'Navigation.ReviewAnalysis',
  SalesAnalytics: 'Navigation.SalesAnalytics',
  SalesEstimator: 'Navigation.SalesEstimator',
  Settings: 'Navigation.Settings',
  ShareOfVoice: 'Navigation.ShareOfVoice',
  Suppliers: 'Navigation.Suppliers',
  SupplierDatabase: 'Navigation.SupplierDatabase',
  SupplierGroups: 'Navigation.SupplierGroups',
  SupplierTracker: 'Navigation.SupplierTracker',
  TheExtension: 'Navigation.TheExtension',
  Toolbox: 'Navigation.Toolbox'
}
